import { ReactNode, useEffect, useState } from "react";
import { imageGallery as listingStayImageGallery } from "./constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import { formatDateTime, useData } from "data/data-provider";
import { Booking } from "api/booking/booking";
import { getProfileFun } from "api/profile/profile";

const CarDetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const thisPathname = useLocation().pathname;

  const {
    selectedCar,
    user,
    selectedBonzahProducts,
    pickupLocation,
    dropOffLocation,
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    endPeriod,
    sameDropoffLocation,
    searchedPickupLoction,
    searchedDropoffLocation,
    selectedExtras,
    setBookingdetails,
  } = useData();
  const [userData, setUserData] = useState<any>();
  let [bonzahProductsLoading, setBonzahProductsLoading] = useState(false);
  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  // const fetchData = async () => {
  //   try {
  //     const data = await getProfileFun();
  //     setUserData(data);
  //   } catch (error) {
  //     console.error("Error fetching profile data", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const getImageGalleryListing = () => {
    if (thisPathname?.includes("/listing-stay-detail")) {
      return listingStayImageGallery;
    }
    if (
      thisPathname?.includes("/listing-stay-detail") ||
      thisPathname?.includes("/car-listing-detail")
    ) {
      return selectedCar?.fleet_photos.map(
        (item, index): ListingGalleryImage => {
          return {
            id: index,
            url: `https://fleet-management-images-upload-be.s3.amazonaws.com/${item?.photo_url}`,
          };
        }
      );
    }
    // if (thisPathname?.includes("/listing-experiences-detail")) {
    //   return listingExperienceImageGallery;
    // }

    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div> */}
        <div className="block lg:hidden">
          {/* <ButtonPrimary
          className="hidden lg:block rounded-lg"
          onClick={() => {
            if (userData?.data.identity_verified !== true) {
              toast.error(
                "Please complete stripe verification to move forward"
              );
              return;
            }
            if (
              !userData?.data?.insurance_verified &&
              !(
                selectedBonzahProducts?.has("CdwByAtig") ||
                selectedBonzahProducts?.has("rlp")
              )
            ) {
              toast.error(
                "Either verify your insurance with canopy or select an inurance coverage option"
              );
            } else {
              handleBooking();
              navigate("/checkout");
            }
          }}
        >
          Reserve
        </ButtonPrimary> */}
        </div>
        {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
      </div>

      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky /> */}
    </div>
  );
};

export default CarDetailPagetLayout;
